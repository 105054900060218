import Siema from 'siema';
window.Siema = Siema;

const siemas = document.querySelectorAll('.carousel');
const length = document.querySelectorAll('#carousel-solution .carousel>.row').length;
const random_slider = Math.floor(Math.random() * (length + 1));
for (const siema of siemas) {
    const mysiema = new Siema({
        selector: siema,
        duration: 250,
        loop: false,
        startIndex: random_slider,
    })
    //setInterval(() => mysiema.next(), 8000)
}